import { m } from 'framer-motion';
// @mui

import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useEffect, useState } from 'react';
import { useDashboardContext } from 'src/context/hooks';
import { IOrganization } from 'src/types/organization';
// ----------------------------------------------------------------------

export default function CompanyPopover() {
  const dashBoardContext = useDashboardContext();
  const [organization, setOrg] = useState<IOrganization[]>(dashBoardContext.allOrgs || []);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const popover = usePopover();

  useEffect(() => {
    if (Array.isArray(dashBoardContext.allOrgs)) {
      setOrg(dashBoardContext.allOrgs);
      // @ts-ignore

      if (dashBoardContext.currentOrg) {
        let counter = 0;
        dashBoardContext.allOrgs.forEach((data) => {
          if (data.org_id === dashBoardContext.currentOrg?.org_id) {
            setSelectedIndex(counter);
          } else {
            counter += 1;
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [dashBoardContext]);

  const onCompanyChange = async (org: IOrganization) => {
    localStorage.setItem('selectedOrganization', JSON.stringify(org.org_id));
  };
  const handleCompanyClick = (company: any, index: any) => {
    // Update the DashboardContext with the clicked company data
    if (dashBoardContext.allOrgs) {
      setSelectedIndex(index);
      onCompanyChange(company);
      dashBoardContext.setCurrentOrgs(company);
    }
  };
  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={popover.open ? 'inherit' : 'default'}
        onClick={popover.onOpen}
        sx={{
          ...(popover.open && {
            bgcolor: (theme) => theme.palette.action.selected,
          }),
        }}
      >
        <Iconify icon="solar:home-bold-duotone" width={24} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 320 }}>
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Organization<Typography component="span">({organization.length})</Typography>
        </Typography>

        <Scrollbar sx={{ height: 320 }}>
          {organization.map((company: any, index: any) => (
            <MenuItem key={company.org_id} sx={{ p: 1 }} selected={index === selectedIndex}>
              <ListItemText
                primary={company.displayName}
                secondary={company.org_id}
                onClick={() => handleCompanyClick(company, index)}
              />
            </MenuItem>
          ))}
        </Scrollbar>
      </CustomPopover>
    </>
  );
}
