import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
// config
import { FIREBASE_API } from 'src/config-global';
//
import useFetcher, { endpoints } from 'src/utils/axios';
import { getStorage } from 'firebase/storage';
import { AuthContext } from './auth-context';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);
const DB = getFirestore(firebaseApp);

// ----------------------------------------------------------------------
enum Types {
  INITIAL = 'INITIAL',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const { fetchData, postData } = useFetcher();
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          if (user.emailVerified) {
            const userProfile = doc(DB, 'users', user.uid);

            const docSnap = await getDoc(userProfile);

            const profile = docSnap.data();

            dispatch({
              type: Types.INITIAL,
              payload: {
                user: {
                  ...user,
                  ...profile,
                  id: user.uid,
                  role: 'admin',
                },
              },
            });
          } else {
            dispatch({
              type: Types.INITIAL,
              payload: {
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);
  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const refreshTokenIfNeeded = async () => {
      if (AUTH.currentUser) {
        const tokenResult = await AUTH.currentUser.getIdTokenResult();
        const expirationTime = tokenResult.expirationTime;
        const currentTime = new Date().getTime();
        const timeToExpiry = new Date(expirationTime).getTime() - currentTime;

        if (timeToExpiry < 5 * 60 * 1000) {
          // Less than 5 minutes to expire
          const newToken = await AUTH.currentUser.getIdToken(true);
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: {
                ...state.user,
                accessToken: newToken,
              },
            },
          });
        }
      }
    };

    refreshTokenIfNeeded();
  }, [state.user, dispatch]);
  // LOGIN
  const login = useCallback(
    async (email: string, password: string, inviteKey: string | null = null) => {
      if (inviteKey === null) {
        await signInWithEmailAndPassword(AUTH, email, password);
      } else {
        const key = inviteKey.split('_');
        const uid = key[0];
        const org_id = key[1];
        await signInWithEmailAndPassword(AUTH, email, password);
        fetchData([
          endpoints.users.invitation_response,
          {
            params: {
              uid,
              org_id,
              accepted: true,
            },
          },
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // CHANGE PASSWORD
  const change_password = useCallback(async (oldPassword: string, newPassword: string) => {
    const user = AUTH.currentUser;
    if (user && user.email) {
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      reauthenticateWithCredential(user, credential)
        .then(() => {
          updatePassword(user, newPassword);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const newUser = await createUserWithEmailAndPassword(AUTH, email, password);
      // TODO: after betaphase activate user
      await sendEmailVerification(newUser.user);
      const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);
      await setDoc(userProfile, {
        uid: newUser.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
      });
      await postData(endpoints.org.create, {
        org_name: `${firstName} ${lastName}`,
        uid: newUser.user?.uid,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postData]
  );
  const register_with_invite = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      uid: string,
      apiKey: string
    ) => {
      signInWithEmailLink(AUTH, email, apiKey).then((newUser) => {
        const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);
        setDoc(userProfile, {
          uid: newUser.user?.uid,
          email,
          displayName: `${firstName} ${lastName}`,
        });

        fetchData([
          endpoints.users.invitation_response,
          { params: { uid: newUser.user?.uid, accepted: true, new_user: true, old_uid: uid } },
        ]);

        if (AUTH.currentUser) {
          updatePassword(AUTH.currentUser, password);
        }
        postData(endpoints.org.create, {
          org_name: `${firstName} ${lastName}`,
          uid: newUser.user?.uid,
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      db: DB,
      currentUser: AUTH.currentUser,
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      firebaseStorage,
      //
      change_password,
      login,
      register_with_invite,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
    }),
    [
      status,
      state.user,

      //
      change_password,
      register_with_invite,
      login,
      logout,
      register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
