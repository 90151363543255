export const GLOSSARY = {
  VIEW_GLOSSARY_LIST: 'viewGlossaryList',
  VIEW_GLOSSARY_ITEM: 'viewGlossary',
};

export const COMPANY_INFO = {
  VIEW_COMPANY: 'viewCompany',
  VIEW_COMPANY_INFO: 'viewCompanyInfo',
};

export const TRANSLATION_ENGINE = {
  VIEW_TRANSLATION_ENGINE: 'viewTranslationEngine',
};

export const TOKEN = {
  VIEW_TOKEN: 'viewToken',
};

export const CREDENTIALS = {
  VIEW_CREDENTIALS: 'getCredentials',
};

export const APPROVALS = {
  VIEW_APPROVALS: 'viewApprovals',
};

export const MANAGE_SETUP = {
  MANAGE_SETUP: 'manageSetup',
};

export const LOGS = {
  VIEW_LOGS: 'viewLogs',
};

export const USER = {
  VIEW_USER: 'viewGetUsers',
};

export const METADATATRANSLATOR = {
  VIEW_METADATATRANSLATOR: 'viewMetaDataTranslator',
};

export const METADATATRANSLATE_JOB = {
  VIEW_METADATATRANSLATOR_JOB: 'viewMetaDataTranslateJob',
};

export const SUPPORT = {
  VIEW_FAQS: 'viewFaqs',
  VIEW_CONTACT: 'viewContact',
};

export const DOCUMENTATION = {
  VIEW_USER_DOCUMENTATION: 'viewUserDocumentation',
  VIEW_ST_TRANSLATOR_DOCUMENTATION: 'viewSTTranslatorDocumentation',
  VIEW_TANSLATE_SETUP_DOCUMENTATION: 'viewTranslateSetupDocumentation',
  VIEW_META_TRANSLATOR_DOCUMENTATION: 'viewMetaTranslatorDocumentation',
  VIEW_MTJOB_DOCUMENTATION: 'viewMTJobDocumentation',
  VIEW_SALESFORCE_DOCUMENTATION: 'viewSalesforceDocumentation',
  VIEW_OPEN_APIS_DOCUMENTATION: 'viewOpenAPIsDocumentation',
  VIEW_EXTENSIONS_DOCUMENTATION: 'viewExtensionsDocumentation',
};
