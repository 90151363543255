// routes
import { paths } from 'src/routes/paths';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: 'Help',
    icon: <Iconify icon="solar:notebook-bold-duotone" />,
    path: '/pages',
    children: [
      {
        subheader: 'Explore',
        items: [
          { title: 'Contact', path: paths.supportPath.contact },
          { title: 'News', path: paths.STNews },
          { title: 'FAQs', path: paths.supportPath.faqs },
          { title: 'Docs', path: paths.docs.overview },
        ],
      },
    ],
  },
];
