// auth
import { AuthGuard } from 'src/auth/guard';
// components
import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import DocsLayout from 'src/layouts/docs/layout';
import { LoadingScreen } from 'src/components/loading-screen';
import { DashBoardProvider } from 'src/context/dashboard/provider';
import { RealTimeProvider } from 'src/context/realtimeUpdates/provider';

// ----------------------------------------------------------------------

const Overview = lazy(() => import('src/pages/docs/overview'));
// User
const UserRoles = lazy(() => import('src/pages/docs/user/roles'));
const Register = lazy(() => import('src/pages/docs/user/register'));
const InviteUser = lazy(() => import('src/pages/docs/user/invite'));
const PasswordReset = lazy(() => import('src/pages/docs/user/passwordReset'));
// ST Setup
const STSetupApproval = lazy(() => import('src/pages/docs/STSetup/approval'));
const OrgCredentials = lazy(() => import('src/pages/docs/STSetup/orgCredentials'));
const TranslationEngine = lazy(() => import('src/pages/docs/STSetup/translationEngine'));
const Glossary = lazy(() => import('src/pages/docs/STSetup/glossary'));
const Notifications = lazy(() => import('src/pages/docs/STSetup/notifications'));
// Meta Translator
const RetrieveMetadata = lazy(() => import('src/pages/docs/metaTranslator/retrieveMetadata'));
const SelectOrgLanguage = lazy(() => import('src/pages/docs/metaTranslator/selectOrgLanguage'));
const TranslateMetadata = lazy(() => import('src/pages/docs/metaTranslator/translateMetadata'));
const DeployMetadata = lazy(() => import('src/pages/docs/metaTranslator/deployMetadata'));
const Limit = lazy(() => import('src/pages/docs/metaTranslator/limit'));
// MTJob
const CreateUpdateJob = lazy(() => import('src/pages/docs/MTJob/createUpdateJob'));
const MTJobStatus = lazy(() => import('src/pages/docs/MTJob/status'));
const CompareOrgs = lazy(() => import('src/pages/docs/MTJob/compareOrgs'));
const OrgsDeployment = lazy(() => import('src/pages/docs/MTJob/orgsDeployment'));
// Salesforce
const LiveTranslation = lazy(() => import('src/pages/docs/salesforce/liveTranslation'));
// OpenAPIs
const Token = lazy(() => import('src/pages/docs/openApis/token'));
// Extensions
const Copado = lazy(() => import('src/pages/docs/extensions/copado'));

// ----------------------------------------------------------------------
const queryParameters = new URLSearchParams(window.location.search);

const inviteKey = queryParameters.get('inviteKey');

export const docsRoutes = [
  {
    path: 'docs',
    element: (
      <AuthGuard inviteKey={inviteKey}>
        <DashBoardProvider>
          <RealTimeProvider>
            <DocsLayout>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </DocsLayout>
          </RealTimeProvider>
        </DashBoardProvider>
      </AuthGuard>
    ),
    children: [
      { path: 'overview', element: <Overview /> },
      { path: 'user/roles', element: <UserRoles /> },
      { path: 'user/register', element: <Register /> },
      { path: 'user/invite', element: <InviteUser /> },
      { path: 'user/passwordReset', element: <PasswordReset /> },
      { path: 'STSetup/approval', element: <STSetupApproval /> },
      { path: 'STSetup/orgCredentials', element: <OrgCredentials /> },
      { path: 'STSetup/translationEngine', element: <TranslationEngine /> },
      { path: 'STSetup/glossary', element: <Glossary /> },
      { path: 'STSetup/notifications', element: <Notifications /> },
      { path: 'metaTranslator/retrieveMetadata', element: <RetrieveMetadata /> },
      { path: 'metaTranslator/selectOrgLanguage', element: <SelectOrgLanguage /> },
      { path: 'metaTranslator/deployMetadata', element: <DeployMetadata /> },
      { path: 'metaTranslator/translateMetadata', element: <TranslateMetadata /> },
      { path: 'metaTranslator/limit', element: <Limit /> },
      { path: 'MTJob/createUpdateJob', element: <CreateUpdateJob /> },
      { path: 'MTJob/status', element: <MTJobStatus /> },
      { path: 'MTJob/compareOrgs', element: <CompareOrgs /> },
      { path: 'MTJob/orgsDeployment', element: <OrgsDeployment /> },
      { path: 'salesforce/liveTranslation', element: <LiveTranslation /> },
      { path: 'openAPIs/token', element: <Token /> },
      { path: 'extensions/copado', element: <Copado /> },
    ],
  },
];
