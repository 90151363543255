import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import usePermission from 'src/permission/has-permission';
import {
  METADATATRANSLATOR,
  METADATATRANSLATE_JOB,
  COMPANY_INFO,
  LOGS,
  MANAGE_SETUP,
} from 'src/permission/all_permissions';
import Iconify from '../../components/iconify';
// ----------------------------------------------------------------------

const icon = (
  name: string // <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
) => (
  // OR
  <Iconify icon={name} />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('mdi:account'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  salesforce: icon('mdi:salesforce'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  library: icon('mdi:library'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  job: icon('iconamoon:synchronize-duotone'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  account: icon('solar:home-bold-duotone'),
  credential: icon('mdi:cloud-key'),
  approval: icon('wpf:approval'),
  security: icon('wpf:key-security'),
  dict: icon('streamline:dictionary-language-book-solid'),
  translation: icon('dashicons:translation'),
};

const GetPermissions = () => ({
  allowedCompanyInfo: usePermission(COMPANY_INFO.VIEW_COMPANY),
  allowedTranslationEngine: usePermission(MANAGE_SETUP.MANAGE_SETUP),
  allowedGlossaryList: usePermission(MANAGE_SETUP.MANAGE_SETUP),
  allowedToken: usePermission(MANAGE_SETUP.MANAGE_SETUP),
  allowedCredentials: usePermission(MANAGE_SETUP.MANAGE_SETUP),
  allowedLogs: usePermission(LOGS.VIEW_LOGS),
  allowedApprovals: usePermission(MANAGE_SETUP.MANAGE_SETUP),
  allowedUser: usePermission(MANAGE_SETUP.MANAGE_SETUP),
  allowedMetadataTranslator: usePermission(METADATATRANSLATOR.VIEW_METADATATRANSLATOR),
  allowedMetadataTranslateJobs: usePermission(METADATATRANSLATE_JOB.VIEW_METADATATRANSLATOR_JOB),
});

// ----------------------------------------------------------------------
export function useNavData() {
  const {
    allowedCompanyInfo,
    allowedTranslationEngine,
    allowedGlossaryList,
    allowedToken,
    allowedCredentials,
    allowedLogs,
    allowedApprovals,
    allowedUser,
    allowedMetadataTranslator,
    allowedMetadataTranslateJobs,
  } = GetPermissions();

  const managementItems = useMemo(() => {
    const items = [];

    if (allowedApprovals.allowedPermission) {
      items.push({
        title: 'Approval',
        path: paths.dashboard.approval,
        icon: ICONS.approval,
      });
    }

    if (allowedCompanyInfo.allowedPermission) {
      items.push({
        title: 'Organization Information',
        path: paths.dashboard.companyInformation,
        icon: ICONS.account,
      });
    }
    if (allowedGlossaryList.allowedPermission) {
      items.push({
        title: 'Glossary',
        path: paths.dashboard.glossary,
        icon: ICONS.dict,
      });
    }
    if (allowedLogs.allowedPermission) {
      items.push({
        title: 'Log',
        path: paths.dashboard.log,
        icon: ICONS.job,
      });
    }
    if (allowedCredentials.allowedPermission) {
      items.push({
        title: 'Org Credential',
        path: paths.dashboard.credentials,
        icon: ICONS.credential,
      });
    }
    if (allowedToken.allowedPermission) {
      items.push({
        title: 'Token',
        path: paths.dashboard.security,
        icon: ICONS.security,
      });
    }

    if (allowedTranslationEngine.allowedPermission) {
      items.push({
        title: 'Translation Engine',
        path: paths.dashboard.translationEngine,
        icon: ICONS.translation,
      });
    }
    if (allowedUser.allowedPermission) {
      items.push({
        title: 'User',
        path: paths.dashboard.user.list,
        icon: ICONS.user,
      });
    }
    return items;
  }, [
    allowedCompanyInfo,
    allowedUser,
    allowedApprovals,
    allowedCredentials,
    allowedToken,
    allowedGlossaryList,
    allowedLogs,
    allowedTranslationEngine,
  ]);

  const overviewItems = useMemo(() => {
    const items = [];

    if (allowedMetadataTranslator.allowedPermission) {
      items.push({
        title: 'Metadata Translator',
        path: paths.dashboard.root,
        icon: ICONS.salesforce,
      });
    }

    if (allowedMetadataTranslateJobs.allowedPermission) {
      items.push({
        title: 'Metadata Translate Job',
        path: paths.dashboard.metadataTranslateJob,
        icon: ICONS.job,
      });
    }

    return items;
  }, [allowedMetadataTranslator, allowedMetadataTranslateJobs]);

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'Simple Translate',
        items: overviewItems,
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'setup',
        items: managementItems,
      },
    ],
    [overviewItems, managementItems]
  );

  return data;
}
