import axios, { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import { useSnackbar } from 'src/components/snackbar';
// config
import { HOST_API } from 'src/config-global';
import { useDashboardContext } from 'src/context/hooks';
import { ILog } from 'src/types/logs';
// ----------------------------------------------------------------------

export const axiosInstance = axios.create({ baseURL: HOST_API });
axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
// ----------------------------------------------------------------------

const useFetcher = () => {
  const idToken = useAuthContext()?.currentUser?.accessToken;
  const org_id = useDashboardContext().currentOrg?.org_id;
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async (args: string | [string, AxiosRequestConfig]) => {
    try {
      const [url, config] = Array.isArray(args) ? args : [args];

      if (idToken) {
        const response = await axiosInstance
          .get(url, {
            ...config,
            headers: {
              Authorization: `Bearer ${idToken}`,
              ...config?.headers,
            },
            params: {
              org_id,
              ...config?.params,
            },
          })
          .catch((error) => {
            enqueueSnackbar(error.message, { variant: error.type });
          });
        if (response?.data.isPopup === true) {
          const log: ILog = response.data;
          enqueueSnackbar(log.message, { variant: log.type });
        }
        return response?.data;
      }
      const response = await axiosInstance.get(url, {
        ...config,
        headers: {
          ...config?.headers,
        },
        params: {
          ...config?.params,
        },
      });

      if (response.data.isPopup === true) {
        const log: ILog = response.data;
        enqueueSnackbar(log.message, { variant: log.type });
      }
      return response.data;
    } catch (err) {
      return err;
    }
  };
  const updateData = async (args: string | [string, AxiosRequestConfig], body: any) => {
    // try {
    const [url, config] = Array.isArray(args) ? args : [args];

    const response = await axiosInstance
      .put(url, body, {
        ...config,
        headers: {
          Authorization: `Bearer ${idToken}`,
          ...config?.headers,
        },
        params: {
          org_id,
          ...config?.params,
        },
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: error.type });
      });
    if (response?.data.isPopup === true) {
      const log: ILog = response.data;
      enqueueSnackbar(log.message, { variant: log.type });
    }
    return response?.data;
  };

  const postData = async (args: string | [string, AxiosRequestConfig], body: any) => {
    // try {
    const [url, config] = Array.isArray(args) ? args : [args];

    const response = await axiosInstance
      .post(url, body, {
        ...config,
        headers: {
          Authorization: `Bearer ${idToken}`,
          ...config?.headers,
        },
        params: {
          org_id,
          ...config?.params,
        },
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: error.type });
      });
    if (response?.data.isPopup === true) {
      const log: ILog = response.data;
      enqueueSnackbar(log.message, { variant: log.type });
    }
    return response?.data;
  };
  const deleteData = async (args: string | [string, AxiosRequestConfig]) => {
    // try {
    const [url, config] = Array.isArray(args) ? args : [args];

    const response = await axiosInstance
      .delete(url, {
        ...config,
        headers: {
          Authorization: `Bearer ${idToken}`,
          ...config?.headers,
        },
        params: {
          org_id,
          ...config?.params,
        },
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: error.type });
      });
    if (response?.data.isPopup === true) {
      const log: ILog = response.data;
      enqueueSnackbar(log.message, { variant: log.type });
    }
    return response?.data;
  };
  return { fetchData, updateData, postData, deleteData };
};

export default useFetcher;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  org: {
    get: '/org/getOrgs',
    put: '/org/updateOrg/',
    create: '/org/createOrg/',
  },
  users: {
    get: '/user/getUsers',
    getMe: '/user/getUser',
    invite_user: '/user/invite_user',
    invitation_response: '/user/invitation_response/',
    deactivate: '/user/deactivateUsers',
    reactivate: '/user/reactivateUsers',
    changeRole: '/user/changeUsersRole',
    put: '/user/put/',
  },
  credential: {
    authorize: '/authentication/getAutherationUrl',
    setCreds: '/authentication/setCredentials',
    get: '/authentication/getCred',
    update: '/authentication/put/',
    delete: '/authentication/delete',
  },
  translatables: {
    get: '/translatables/get',
    put: '/translatables/put/',
    getInfo: '/translatables/getInfo',
    getInfoMTJob: '/translatables/getInfoMTJob',
    fuzzyLoadingMTJob: '/translatables/fuzzyGetMTJob',
    fuzzyLoading: '/translatables/fuzzyGet',
  },
  metadata: {
    retrieve: '/metadata/retrieve/',
    deploy: '/metadata/deploy/',
  },
  glossary: {
    get: '/glossary/get',
    getSingleList: '/glossary/get/singleGlossaryList/',
    post: '/glossary/post/',
    delete: '/glossary/delete',
    put: '/glossary/put/',
    getList: '/glossary/get/fetchGlossaryList/',
    createList: '/glossary/post/createGlossaryList',
    updateList: '/glossary/put/updateGlossaryList/',
    deleteList: '/glossary/delete/deleteGlossaryList/',
    deleteManyList: '/glossary/delete/deleteManyGlossaryList/',
  },
  translateEngine: {
    get: '/translateEngine/get',
    post: '/translateEngine/post/',
    delete: '/translateEngine/delete',
    deleteMany: '/translateEngine/deleteMany/',
    put: '/translateEngine/put/',
    testTranslation: '/translateEngine/testTranslation/',
  },
  translator: '/translator/translate/',
  authenticate: '/translator/authenticate/',
  jobs: '/job/getjobs',
  MTJObs: {
    create: 'metadataTranslateJob/createMTJob/',
    getJob: 'metadataTranslateJob/getMTJob',
    update_status: 'metadataTranslateJob/updateStatusMTJob/',
    approve: 'metadataTranslateJob/approveMTJob/',
    deploy: 'metadataTranslateJob/deployJob/',
    getTranslatables: 'metadataTranslateJob/getMTJobTranslatables',
    addToMTJob: 'metadataTranslateJob/addToMTJob/',
    removeFromMTJob: 'metadataTranslateJob/removeFromMTJob/',
    getMTTranslatablesDetails: 'metadataTranslateJob/getMTJobDetailsData/',
    getInfo: 'metadataTranslateJob/getInfo',
    getMetadataApiName: 'metadataTranslateJob/getMetadataApiNames',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  approval: {
    get: 'approval/get',
    put: 'approval/put/',
  },
  environment: 'environment/',
  logs: 'logs/getLogs',
  token: {
    create: 'token/create/',
    get: 'token/get',
    put: 'token/put/',
  },
  mail: 'mail/',
};
